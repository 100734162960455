.avatar_choosen{
    position: relative;
    width: 100%;
    height: 100%;
    max-width:45vh;
    min-height: 50px;
    min-width: 50px;
}
.avatar_customization{
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 12vh;
    max-width: 12vh;
    min-height: 50px;
    min-width: 50px;
}


/* clip-path: circle(90px at 40px 80px);

 position: relative;
    top: 20px;
    left: 50px;
    zoom: 1.5;
    border: 1px solid;*/
